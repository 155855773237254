import mapboxgl from 'mapbox-gl';

mapboxgl.accessToken = 'pk.eyJ1Ijoid2lsZGlzaCIsImEiOiJjbG9ydjliZWUwcTRpMmpvMWs0aXpoeDd0In0.MxDCE5aOqkHKSFKKKDUB6w';

document.addEventListener("DOMContentLoaded", () => {
    if(document.body.classList.contains("home")) {
        /* eslint-disable no-unused-vars*/
        const map = new mapboxgl.Map({
            container: 'map',
            style: 'mapbox://styles/mapbox/streets-v12',
            center: [2.3977636726800466, 47.08128211611109],
            zoom: 5,
            minZoom: 4,
        });

        // Create a default Marker and add it to the map.
        const frejus = new mapboxgl.Marker({color: '#113355'})
        .setLngLat([6.757619730136157, 43.4347146059887])
        .addTo(map);

        frejus.getElement().addEventListener("click", () => {
          window.location.href = "instituts/institut-de-frejus/";
        });

        // Create a default Marker and add it to the map.
        const suresnes = new mapboxgl.Marker({color: '#113355'})
        .setLngLat([2.223348472464952, 48.87279477620823])
        .addTo(map);

        suresnes.getElement().addEventListener("click", () => {
          window.location.href = "instituts/institut-de-suresnes/";
        });

        // Create a default Marker and add it to the map.
        const marseille = new mapboxgl.Marker({color: '#113355'})
        .setLngLat([5.375655058971759, 43.289839352040666])
        .addTo(map);

        marseille.getElement().addEventListener("click", () => {
          window.location.href = "instituts/institut-de-marseille/";
        });

        // Create a default Marker and add it to the map.
        const aix = new mapboxgl.Marker({color: '#113355'})
        .setLngLat([5.436793268766516, 43.50307474970201])
        .addTo(map);

        aix.getElement().addEventListener("click", () => {
          window.location.href = "instituts/institut-de-aix-en-provence/";
        });

        // Create a default Marker and add it to the map.
        const cnit = new mapboxgl.Marker({color: '#113355'})
        .setLngLat([2.2394825820158206, 48.892830141387925])
        .addTo(map);

        cnit.getElement().addEventListener("click", () => {
          window.location.href = "instituts/institut-du-cnit-la-defense/";
        });

        // Create a default Marker and add it to the map.
        const toulon = new mapboxgl.Marker({color: '#113355'})
        .setLngLat([5.9528167, 43.1224037])
        .addTo(map);

        toulon.getElement().addEventListener("click", () => {
          window.location.href = "instituts/institut-de-toulon/";
        });
    }
});
