// import external dependencies
import jquery from 'jquery';
global.$ = global.jQuery = jquery;

import 'bootstrap/js/dist/modal';
import 'bootstrap/js/dist/collapse';
import 'bootstrap/js/dist/offcanvas';
import 'bootstrap/js/dist/dropdown';
import 'bootstrap/js/dist/tab';

/*
 * Modules
 */
import './modules/sliders';
import './modules/aos';
import './modules/mapbox';
import './hamburger';
import './video';
