/* eslint-disable no-unused-vars */
import Swiper from 'swiper/bundle';

/* Slider home-treatments */
var treatments_slide = new Swiper('.hometreatments-swiper', {
  slidesPerView: 'auto',
  spaceBetween: 0,
  centeredSlides: false,
  loop: true,
  breakpoints: {
    992: {
      loop: false,
    },
  },
});

/* Slider home-edito */
var homeedito_thumbs_swiper = new Swiper('.homeedito-thumbs-swiper', {
  slidesPerView: 'auto',
  spaceBetween: 0,
});

var homeedito_swiper = new Swiper('.homeedito-swiper', {
  loop: false,
  slidesPerView: 'auto',
  spaceBetween: 18,
  navigation: {
    nextEl: '.homeedito-next',
    prevEl: '.homeedito-prev',
  },
  thumbs: {
    swiper: homeedito_thumbs_swiper,
  },
});

/* Slider home */
var home_swiper = new Swiper('.home-swiper', {
  loop: true,
  slidesPerView: 'auto',
  spaceBetween: 18,
  grabCursor: true,
  autoplay: {
    delay: 2500,
    disableOnInteraction: false,
  },
  navigation: {
    nextEl: '.home-next',
    prevEl: '.home-prev',
  },
});

/* Slider members-institute */
var members_swiper = new Swiper('.members-swiper', {
  loop: true,
  slidesPerView: 'auto',
  navigation: {
    nextEl: '.members-next',
    prevEl: '.members-prev',
  },
});

/* Slider reviews */
var reviews_slide = new Swiper('.reviews-swiper', {
  slidesPerView: 'auto',
  spaceBetween: 50,
  centeredSlides: false,
  navigation: {
    nextEl: '.reviews-next',
    prevEl: '.reviews-prev',
  },
});

/* Slider experts */
var experts_swiper = new Swiper('.experts-swiper', {
  slidesPerView: 'auto',
  spaceBetween: 18,
  centeredSlides: false,
  navigation: {
    nextEl: '.experts-next',
    prevEl: '.experts-prev',
  },
});



/* eslint-enable no-unused-vars */
